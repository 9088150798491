import React, { Component } from 'react'

import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'

import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import PageCtaBoxes from '../components/page-cta-boxes'
import PageOverview from '../components/page-overview'
import PageRuledList from '../components/page-ruled-list'
import { fontLight, fontRegular, theme } from '../util/style'

import Image from '../components/page-image-new'

import Image1 from '../images/etc/happyhaus-gallery-2023-08-24-photo-gordon-1020249.jpg'
import styled from 'styled-components'
import HalfGridRow from '../components/half-grid-row'
import HalfGridRowItem from '../components/half-grid-row/half-grid-row-item'
import PageHeader from '../components/v2/PageHeader'
import BlogContainer from '../components/blog-page/PageBlog/BlogContainer'
import CopyStackRow from '../components/v2/CopyStackRow'
import { Link } from 'gatsby'
import BlogRelated from '../components/blog-page/PageBlog/BlogRelated'
const ogImages = [`https://happyhaus.com.au${Image1}`]

const ImageWork = 'etc/happyhaus-gallery-2022-11-25-photo-flower-1110443.jpg'

//<PageContact  submitted={false} />

class Page extends Component {
  render() {
    return (
      <>
        <SEO
          title="Join our team"
          description="We're always on the lookout for talented people to join our sales, design and build teams. Contact us with your expression of interest and CV via homes@happyhaus.com.au"
          images={ogImages}
        />
        <PageHeader
          title={'Join our team'}
          style={{ fontFamily: theme.font.fontFamilyLight }}
        />

        <BlogContainer attachTop pb={['44px', '94px']}>
          <CopyStackRow
            items={[
              {
                heading: 'Carpenter',
                content: (
                  <>
                    <p>
                      We’re looking for a talented Carpenter experienced in
                      their craft. Join a team of long-term Happy Haus
                      carpenters. Our in-haus design team support all projects.
                      We design for build efficiency in high-end homes. Our
                      priority is quality and craftsmanship.
                    </p>
                    <p>
                      All of our projects are within 15 km of Brisbane CBD. We
                      offer competitive wages based on skill-level.
                    </p>
                    <p>
                      Let's chat if you're looking for a change or want to build
                      architectural homes with us.
                    </p>
                    <p>
                      Read about the role{' '}
                      <a href="/pdfs/happyhaus-hiring-carpenter.pdf">here</a>{' '}
                      (PDF) and apply via{' '}
                      <a href="mailto:homes@happyhaus.com.au">
                        homes@happyhaus.com.au
                      </a>
                    </p>
                  </>
                ),
              },
            ]}
          />
        </BlogContainer>
        <Row>
          <Flex flexWrap="wrap">
            <Box width={[1]} px={2} mr={[0, 0]}>
              <Image src={ImageWork} alt="Work Image" />
            </Box>
          </Flex>
        </Row>
        <BlogRelated
          data={{
            relatedPosts: [
              {
                slug: 'top-sales-results-in-new-farm-and-tarragindi',
                title: 'Top sales results in New Farm and Tarragindi',
                category: ['Noteworthy'],
                thumbnail: {
                  file: {
                    path: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100859.jpg',
                  },
                },
              },
              {
                title: 'Making more neighbourhood memories',
                slug: 'silex-haus-a-legacy-of-memories',
                category: ['Owners Club'],
                thumbnail: {
                  file: {
                    path: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010415.jpg',
                  },
                },
              },
            ],
          }}
        />
      </>
    )
  }
}

export default Page

const HorizontalRule = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #888;
  margin: 1em 0;
  padding: 0;
`

const Notifbar = styled(Box)`
  background-color: #303030;
  color: white;
  text-align: center;
  font-size: 17px;
  ${fontRegular}

  a {
    color: white;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
`

const List = styled(Box)`
  li {
    padding-bottom: 0.8rem;
  }
`

const CopyWrapper = styled(Box)`
  font-family: ${theme.font.fontFamilySerifThin};
  text-align: left;
  font-size: 22px;
  line-height: 130%;

  h3 {
    font-size: 30px;
    line-height: 130%;
    font-weight: normal;
    @media (max-width: 800px) {
      font-size: 24px;
    }
  }

  p {
    margin-top: 0%;
    margin-bottom: 1rem;
    width: 100%;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  a {
    border-bottom: 1px solid ${theme.colors.grey11};
    color: ${theme.colors.grey1} !important;
    &:hover {
      border-bottom: 1px solid ${theme.colors.grey2};
    }
  }
`
